/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from 'react-accessible-accordion';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
// import { Blog } from 'styles/blog-new';
import GetStartedCenter from 'components/GetStartedCenter';
import ArchivePostSingle from 'components/ArchivePostSingle';

// import { Main } from 'styles/blogPost';
import { signUp } from '../utils/contentProvider/function';
import 'styles/blogPost.scss';
import 'styles/blog-new.scss';
const BlogPostTemplate = ({
  data: {
    contentfulBlogPost: post,
    allContentfulBlogPost: { edges: relatedPosts = [] },
  },
  location,
}) => {
  function contentClickHandler(e) {
    if (e.target.closest('.entry-content') && e.target.closest('a')) {
      e.preventDefault();

      const url = e.target.href || e.target.parentElement.href;

      window.open(url, '_blank');
    }
  }

  function getMetaDate(date) {
    let newDate = date;

    newDate = newDate.replace('th', '');
    newDate = newDate.replace('st', '');
    newDate = newDate.replace('nd', '');
    newDate = newDate.replace('rd', '');

    return new Date(newDate).toISOString();
  }

  useEffect(() => {
    window.addEventListener('click', contentClickHandler);

    return () => window.removeEventListener('click', contentClickHandler);
  });

  return (
    <Layout location={location} blog>
      {({ toggleSignup }) => (
        <>
          <Meta
            title={`${post.title} | ruttl Blog`}
            description={post.excerpt.excerpt}
            author={post?.guestAuthor ? post?.guestName : post?.author.name}
            image={post.archiveThumbnail.resize.src}
            url={`https://ruttl.com/blog/${post.slug}/`}
            date={getMetaDate(post.publishDate)}
          />
          <main className="blog-post-styled-main">
            <div className="blog-new-styled-main">
              <section className="blogpost">
                <div className="container">
                  <div className="blogpost-top">
                    <div className="flex flex-wrap justify-content-between">
                      <div>
                        <Link href="/blog/" className="breadcrum">
                          <div
                            className="nav-img-wrapper"
                            style={{ backgroundColor: '#FFFAED' }}>
                            <img src="/assets/img/blog.svg" alt="Blog" />
                          </div>
                          Blog
                        </Link>
                        <svg
                          width="8"
                          height="14"
                          viewBox="0 0 8 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M1 13L7 7L1 1"
                            stroke="#160647"
                            strokeOpacity="0.4"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span className="breadcrum breadcrum-inner box">
                          {post?.category
                            ? post?.category[0]?.name
                            : 'Uncategorised'}
                        </span>
                      </div>
                      {/* <div className="flex flex-wrap align-items-center hidden-mob">
                        <a href="/blog/" className="tag box">
                          All
                        </a>
                        <a href="/blog/" className="tag box">
                          Useful tips
                        </a>
                        <a href="/blog/" className="tag box">
                          Design &amp; Development
                        </a>
                        <a href="/blog/" className="tag box">
                          Productivity
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="container container-small">
                  <div className="featured-image">
                    <Img
                      alt={post?.title}
                      className="featured-image"
                      fluid={post?.heroImage?.fluid}
                    />
                  </div>
                  <h1 className="blogpost-heading">{post?.title}</h1>
                  <div className="flex flex-wrap justify-content-between align-items-center">
                    <div className="blogpost-details">
                      <div className="flex align-items-center">
                        <Img
                          alt={
                            post?.guestAuthor
                              ? post?.guestName
                              : post?.author.name
                          }
                          fluid={
                            post?.guestAuthor
                              ? post?.guestImage?.fluid
                              : post?.author?.image?.fluid
                          }
                          className="author-image"
                          style={{ width: 64 }}
                        />
                        <div>
                          <span className="author">
                            Written by&nbsp;
                            <span className="semi-bold">
                              {post?.guestAuthor
                                ? post?.guestName
                                : post?.author.name}
                            </span>
                          </span>
                          <div>
                            <span className="published">
                              {`Published on ${post?.publishDate}`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="blogpost-main">
                    <div className="flex flex-margin flex-wrap">
                      <div className="blogpost-left">
                        <div className="sticky">
                          {/* <Accordion>
                            <AccordionItem className="faq-single">
                              <AccordionItemHeading className="question">
                                <AccordionItemButton>
                                  <img
                                    src="/assets/img/multiscreen-testing.svg"
                                    alt=""
                                  />
                                  <h4>Table of contents</h4>
                                  <svg
                                    width="14"
                                    height="8"
                                    viewBox="0 0 14 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M1 0.999999L7 7L13 1"
                                      stroke="#150546"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel className="answer">
                                Design fix: Tips to improve feedback loops.
                              </AccordionItemPanel>
                            </AccordionItem>
                          </Accordion> */}
                          <div className="blog-get-started">
                            <h4>Get started with ruttl for free! 🙀</h4>
                            <Link
                              type="button"
                              to="/get-started"
                              id="get-started-static-blog"
                              className="button">
                              Get Started
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 512 512">
                                <path
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="48"
                                  d="M268 112l144 144-144 144M392 256H100"></path>
                              </svg>
                            </Link>
                          </div>

                          <div className="social-links">
                            <ul className="flex justify-content-center">
                              <li>
                                <a
                                  href={encodeURI(
                                    `https://www.facebook.com/sharer/sharer.php?u=https://ruttl.com/blog/${post.slug}/`,
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  <svg width="46" height="46" fill="none">
                                    <circle
                                      opacity=".1"
                                      cx="23"
                                      cy="23"
                                      r="23"
                                      fill="#160647"
                                    />
                                    <g clipPath="url(#a)">
                                      <path
                                        d="M22.574 32.372a8.944 8.944 0 1 0 0-17.889 8.944 8.944 0 0 0 0 17.889Z"
                                        fill="#160647"
                                        fillOpacity=".56"
                                      />
                                      <path
                                        d="m26.056 26.013.396-2.586h-2.48v-1.678c0-.707.345-1.397 1.457-1.397h1.129V18.15s-1.024-.174-2.003-.174c-2.043 0-3.378 1.237-3.378 3.48v1.97h-2.272v2.587h2.272v6.25a8.978 8.978 0 0 0 2.794 0v-6.25h2.085Z"
                                        fill="#fff"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="a">
                                        <path
                                          fill="#fff"
                                          transform="translate(13.63 14.481)"
                                          d="M0 0h17.889v17.889H0z"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </a>
                              </li>
                              <li>
                                <a
                                  href={encodeURI(
                                    `https://twitter.com/home?status=https://ruttl.com/blog/${post.slug}/ ${post.title}`,
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  <svg width="46" height="46" fill="none">
                                    <circle
                                      opacity=".1"
                                      cx="23"
                                      cy="23"
                                      r="23"
                                      fill="#160647"
                                    />
                                    <g clipPath="url(#a)">
                                      <path
                                        d="M31.518 17.9a6.75 6.75 0 0 1-2.007.575 3.642 3.642 0 0 0 1.537-2.023 6.813 6.813 0 0 1-2.22.888 3.421 3.421 0 0 0-2.551-1.155c-1.93 0-3.495 1.637-3.495 3.656 0 .287.03.566.09.833-2.905-.152-5.48-1.608-7.204-3.82-.301.54-.474 1.168-.474 1.838 0 1.269.617 2.388 1.555 3.043a3.366 3.366 0 0 1-1.583-.457v.046c0 1.771 1.204 3.249 2.803 3.585a3.354 3.354 0 0 1-1.578.062c.445 1.453 1.736 2.51 3.265 2.54a6.82 6.82 0 0 1-5.175 1.513 9.581 9.581 0 0 0 5.359 1.643c6.429 0 9.945-5.571 9.945-10.402 0-.159-.004-.316-.01-.473a7.29 7.29 0 0 0 1.743-1.893Z"
                                        fill="#160647"
                                        fillOpacity=".56"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="a">
                                        <path
                                          fill="#fff"
                                          transform="translate(14.481 16.185)"
                                          d="M0 0h17.037v14.482H0z"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </a>
                              </li>
                              <li>
                                <a
                                  href={encodeURI(
                                    `https://www.linkedin.com/shareArticle?mini=true&url=https://ruttl.com/blog/${post.slug}/&title=${post.title}&summary=${post.excerpt.excerpt}`,
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  <svg width="46" height="46" fill="none">
                                    <circle
                                      opacity=".1"
                                      cx="23"
                                      cy="23"
                                      r="23"
                                      fill="#160647"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M20.992 20.633h2.9v1.445c.418-.831 1.49-1.578 3.099-1.578 3.085 0 3.818 1.654 3.818 4.689v5.62h-3.124v-4.93c0-1.727-.418-2.702-1.481-2.702-1.475 0-2.088 1.05-2.088 2.702v4.93h-3.124V20.633Zm-5.356 10.043h3.124V20.5h-3.124v10.175Zm3.571-13.493a1.975 1.975 0 0 1-.588 1.407 2.022 2.022 0 0 1-2.84 0 1.99 1.99 0 0 1-.588-1.407c0-.529.211-1.035.588-1.408a2.017 2.017 0 0 1 2.84 0c.377.373.588.879.588 1.408Z"
                                      fill="#160647"
                                      fillOpacity=".56"
                                    />
                                  </svg>
                                </a>
                              </li>
                              <li>
                                <button
                                  type="button"
                                  onClick={() => signUp(toggleSignup)}>
                                  <svg width="46" height="46" fill="none">
                                    <circle
                                      opacity=".1"
                                      cx="23"
                                      cy="23"
                                      r="23"
                                      fill="#160647"
                                    />
                                    <path
                                      d="M20.992 18.22c-1.7 1.91-2.867 4.65-2.975 4.908L15 21.838l4.008-4.009 1.984.391Zm1.65 8.875s3.116-1.29 4.908-3.083c4.5-4.5 3.75-8.017 3.508-8.809-.791-.25-4.308-.99-8.808 3.51-1.792 1.791-3.083 4.907-3.083 4.907l3.475 3.475Zm5.4-1.825c-1.909 1.7-4.65 2.867-4.909 2.975l1.292 3.017 4.008-4.009-.391-1.983Zm-7.209 2.66c0 .69-.283 1.315-.733 1.765-.983.983-5.1 1.567-5.1 1.567s.583-4.117 1.567-5.1a2.498 2.498 0 0 1 4.266 1.767Zm3.334-7.5c0-.918.75-1.668 1.666-1.668.917 0 1.667.75 1.667 1.666 0 .917-.75 1.667-1.667 1.667-.916 0-1.666-.75-1.666-1.666Z"
                                      fill="#160647"
                                      fillOpacity=".56"
                                    />
                                  </svg>
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {post.bodyRtf ? (
                        <div className="entry-content">
                          {documentToReactComponents(post.bodyRtf.json)}
                        </div>
                      ) : (
                        <div
                          className="entry-content"
                          dangerouslySetInnerHTML={{
                            __html: post.body.childMarkdownRemark.html,
                          }}
                        />
                      )}
                      {post?.guestAuthor && (
                        <div className="entry-content guest-desc">
                          <Img
                            alt={post?.guestName}
                            fluid={post?.guestImage?.fluid}
                            className="author-image"
                            style={{ width: 64 }}
                          />
                          <span className="author">
                            Written by&nbsp;
                            <span className="semi-bold">{post?.guestName}</span>
                          </span>
                          <p>
                            {
                              post?.guestAuthorDescription
                                ?.guestAuthorDescription
                            }
                          </p>
                        </div>
                      )}

                      {/* <div className="entry-content">
                        <p>
                          There’s a lot of difference between the websites of 2010
                          and that of 2022. Being a largely creative field, the
                          world of web designing sees a lot of changing trends,
                          experiences, and visual design elements. Working in this
                          field can be rewarding but you can easily get stuck in a
                          rut if you hit a roadblock when it comes to inspiration.
                          One way to tackle this issue is to subscribe to web design
                          blogs. Here are 9 such blogs you can bookmark today!
                        </p>
                        <h2>Smashing Magazine</h2>
                        <p>
                          <a href="/">Smashing magazine&nbsp;</a>
                          covers many areas such as Accessibility, Usability,
                          Design, Web Design, and so on. You can also search for
                          specific articles through the search bar given at the top.
                        </p>
                        <p>
                          This blog tops the recommendation list of thousands of web
                          designers as their go-to place for inspiration and
                          questions. The one thing that sets them apart is they also
                          have a section where web designers can look for jobs, even
                          remote, anywhere in the world. With freebies such as
                          templates and plugins, live workshops, e-books and guides,
                          this blog has it all. You can also subscribe to their
                          email newsletter that gets delivered to you every week.
                        </p>
                        <img src="/assets/img/blog-index.jpg" alt="" />
                        <h3>More about the Magazine...</h3>
                        <ol>
                          <li>
                            The one thing that sets them apart is they also have a
                            section where web designers can look for jobs.
                          </li>
                          <li>
                            The one thing that sets them apart is they also have a
                            section where web designers can look for jobs.
                          </li>
                          <li>
                            The one thing that sets them apart is they also have a
                            section where web designers can look for jobs.
                          </li>
                          <li>
                            The one thing that sets them apart is they also have a
                            section where web designers can look for jobs.
                          </li>
                          <li>
                            The one thing that sets them apart is they also have a
                            section where web designers can look for jobs.
                          </li>
                          <li>
                            The one thing that sets them apart is they also have a
                            section where web designers can look for jobs.
                          </li>
                          <li>
                            The one thing that sets them apart is they also have a
                            section where web designers can look for jobs.
                          </li>
                          <li>
                            The one thing that sets them apart is they also have a
                            section where web designers can look for jobs.
                          </li>
                          <li>
                            The one thing that sets them apart is they also have a
                            section where web designers can look for jobs.
                          </li>
                          <li>
                            The one thing that sets them apart is they also have a
                            section where web designers can look for jobs.
                          </li>
                          <li>
                            The one thing that sets them apart is they also have a
                            section where web designers can look for jobs.
                          </li>
                          <li>
                            The one thing that sets them apart is they also have a
                            section where web designers can look for jobs.
                          </li>
                        </ol>
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/AyZAJQgrXKk"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                        <blockquote className="twitter-tweet">
                          <p lang="en" dir="ltr">
                            Tag a web designer to show your support! 😂
                            <a href="https://twitter.com/hashtag/webdesign?src=hash&amp;ref_src=twsrc%5Etfw">
                              #webdesign&nbsp;
                            </a>
                            <a href="https://twitter.com/hashtag/mrbean?src=hash&amp;ref_src=twsrc%5Etfw">
                              #mrbean&nbsp;
                            </a>
                            <a href="https://twitter.com/hashtag/webdesigning?src=hash&amp;ref_src=twsrc%5Etfw">
                              #webdesigning&nbsp;
                            </a>
                            <a href="https://twitter.com/hashtag/uiux?src=hash&amp;ref_src=twsrc%5Etfw">
                              #uiux&nbsp;
                            </a>
                            <a href="https://t.co/gegMoPfUaw">
                              pic.twitter.com/gegMoPfUaw&nbsp;
                            </a>
                          </p>
                          &mdash; ruttl (@RuttlApp)&nbsp;
                          <a href="https://twitter.com/RuttlApp/status/1536636670724145152?ref_src=twsrc%5Etfw">
                            June 14, 2022
                          </a>
                        </blockquote>
                        <script
                          async
                          src="https://platform.twitter.com/widgets.js"
                          charset="utf-8"></script>
                      </div> */}
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {relatedPosts?.length > 0 && (
              <section className="related-post-main">
                <div className="container">
                  <h2 className="heading">Similar posts</h2>
                  <div className="flex flex-wrap" style={{ margin: '0 -15px' }}>
                    {relatedPosts.map(({ node }) => (
                      <ArchivePostSingle
                        key={node.id}
                        link={`/blog/${node.slug}/`}
                        title={node.title}
                        image={node.archiveThumbnail.fluid}
                        excerpt={node.excerpt.excerpt}
                      />
                    ))}
                  </div>
                </div>
              </section>
            )}
            <GetStartedCenter toggleSignup={toggleSignup} />
          </main>
        </>
      )}
    </Layout>
  );
};

BlogPostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      excerpt {
        excerpt
      }
      publishDate(formatString: "MMMM Do, YYYY")
      category {
        name
      }
      heroImage {
        fluid(
          maxWidth: 1170
          maxHeight: 365
          quality: 70
          resizingBehavior: THUMB
        ) {
          ...GatsbyContentfulFluid_withWebp
        }
        resize(width: 1200) {
          src
        }
      }
      archiveThumbnail {
        resize(width: 1200) {
          src
        }
      }
      author {
        name
        title
        company
        image {
          fluid(maxWidth: 65, maxHeight: 65) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      guestAuthor
      guestName
      guestAuthorDescription {
        guestAuthorDescription
      }
      guestImage {
        fluid(maxWidth: 100, maxHeight: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      bodyRtf {
        json
      }
    }
    allContentfulBlogPost(limit: 3, filter: { slug: { nin: [$slug] } }) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          archiveThumbnail {
            fluid(
              maxWidth: 600
              maxHeight: 456
              quality: 70
              resizingBehavior: THUMB
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          excerpt {
            excerpt
          }
        }
      }
    }
  }
`;
